<template>
  <div class="ele-body">
    <el-card shadow="never">

      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="title" label="订单交易类型" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="note" label="支付平台" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="value" label="扣除手续费%" show-overflow-tooltip min-width="120"/>
          <el-table-column label="设置时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
                       v-if="permission.includes('sys:handling:edit')">编辑
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑手续费配置':'添加手续费配置'" :visible.sync="showEdit"
               @closed="[editForm={}]"
               :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">

        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">

          <el-form-item label="订单交易类型：" prop="title" label-width='160px'>
            <el-input v-model="editForm.title" placeholder="请输入订单交易类型" clearable class="w-400" disabled/>
          </el-form-item>

          <el-form-item label="支付平台：" prop="note" label-width='160px'>
            <el-input v-model="editForm.note" placeholder="请输入支付平台" clearable class="w-400"/>
          </el-form-item>

          <el-form-item label="扣除手续费%：" prop="value" label-width='160px'>
            <el-input v-model="editForm.value" placeholder="请输入扣除手续费" clearable class="w-400"/>
          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">修改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'

export default {
  name: 'SysDownload',
  data() {
    return {
      table: {
        url: '/config/index',
        where: {type:3}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {}, // 表单数据
      editRules: { // 表单验证规则
        title: [{
          required: true,
          message: '请输入订单交易类型',
          trigger: 'blur'
        }],
        note: [{
          required: true,
          message: '请输入支付平台',
          trigger: 'blur'
        }],
        value: [{
          required: true,
          message: '请输入扣除手续费',
          trigger: 'blur'
        }],
      },
      userInfo: {},
      header: {
        'Content_type': 'multipart/form-data',
      },
      file: [],
      formData: [],
      android: '',
      is_upload: false,
    }
  },
  created() {
    var Accept = 'application/json, text/plain, */*'
    var token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqd3RfeWgiLCJleHAiOjE2MzAyODg1NTIsInN1YiI6IllIIiwiYXVkIjoiZXZlcnkiLCJuYmYiOjE2Mjk2ODM3NTIsImlhdCI6MTYyOTY4Mzc1MiwianRpIjoxMDAwMSwidWlkIjoxfQ._LR36-HqeBSfcWlpklXoLRJ5kGOcGaqW8d3EY7cfZO0'
    this.userInfo.Authorization = token
    this.userInfo.Accept = Accept
  },
  computed: {
    ...mapGetters(['permission']),
  },
  mounted() {
  },
  methods: {
    // 限制文件类型
    beforeUpload(file) {
      this.is_upload = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['apk']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传的文件只能是 .apk 格式!')
        return false
      }
    },
    handleChange(file, fileList) {
      this.is_upload = true
      this.fileList = fileList.slice(-3)
    },
    handleAvatarSuccess(response, file, fileList) {
      this.android = response
      this.editForm.android = response
    },
    /**
     * 显示编辑
     */
    edit(row) {
      this.$http.get('/config/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data
          this.showEdit = true
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          this.$http.post('/config/edit', this.editForm).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.showEdit = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        } else {
          return false
        }
      })
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据')
        let ids = this.choose.map(d => d.id)
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: ids
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      } else { // 单个删除
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: row.id
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload,
.ele-block >>> .el-upload-dragger {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 30px !important;
}
</style>
